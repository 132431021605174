import React, {Component} from 'react';
import './Body.css';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div id="pg">
                <div className="Logo">
                    <img src="img/logoCybernetic_798x254.png" alt=""/>
                </div>
                <div>SmarterASP - bazzillion.com</div>

                <div id="footer">
                    <div className="copyright">
                        &copy;2022 Bazzillion/Cybernetic Innovations. All rights reserved.
                    </div>
                </div>
            </div>
        )
    }
}
